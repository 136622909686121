import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, TableCell, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import GenericTable from "../../../components/genericTable/GenericTable";
import {
  convertUTCToLocal,
  getOnlyDate,
} from "../../../utils/datesUtils/dateUtils";
import NavLinkAdministratorGroups from "../createActividadParroquial/LinksAdministrationGroup";
import {
  ActividadParroquialRoutes,
  ActividadParroquialTextos,
  CreateActividadParroquialEnum,
} from "../../../enums/pages/CreateActividadParroquialEnum";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { stylesListadoActividadParroquial } from "./ListadoActividadesParroquialesStyles";
import turnoExpecificationService from "../../../services/turnosExpecification";
import moment from "moment";
import {
  formatCriteryFeriados,
  formatPeriodicity,
} from "../../../utils/pages/TurnoUtils";
import {
  ActividadParroquialNombre,
  Group,
} from "../InterfacesActividadParroquial";
import actividadParroquialService from "../../../services/actividadParroquial/ActividadParroquialService";
import { sortByProperty } from "../../../utils/actividadParroquialUtils/UtilsActividadParroquial";
import ActividadParroquialInputs from "../PeopleActividadParroquial/ActividadParroquialInputs";
import grupoParroquialService from "../../../services/grupoParroquial/GrupoParroquialService";
import LinksAndHeader from "../PeopleActividadParroquial/LinksAndHeader";

const ListadoDeActividadesParroquialesPendientes = () => {
  const navigate = useNavigate();
  const [forceRefresh, setForceRefresh] = React.useState(new Date());
  const [totalTurnos, setTotalTurnos] = React.useState(0);
  const [groups, setGroups] = useState<Group[]>([]);
  const [activity, setActivity] = useState({
    activity: {
      nameId: 0,
      groupId: 0,
      encuentroId: 0,
    },
  });
  const [encuentro, setEncuentro] = useState([]);
  const location = useLocation();
  const nameId = location.state?.nameId;
  const groupId = location.state?.groupId;
  const [actividadParroquialNombres, setActividadParroquialNombre] = useState<
    ActividadParroquialNombre[]
  >([]);
  const actividadParroquialColumns = [
    {
      name: "Fecha de inicio / fin",
      accessor: "startTime",
      customReder: (row: any, column: any) => {
        const date = new Date(row.startTime);
        return (
          <TableCell>
            {row.startTime && row.endTimeRepetition
              ? `${moment(getOnlyDate(row.startTime)).format("DD-MM-YYYY")} / ${moment(getOnlyDate(row.endTimeRepetition)).format("DD-MM-YYYY")}`
              : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Hora de Inicio / Fin",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.startHour && row.endHour
              ? `${convertUTCToLocal(row.startHour)} / ${convertUTCToLocal(row.endHour)}`
              : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Periodicidad",
      accessor: "",
      customReder: (row: any, column: any) => {
        return <TableCell>{formatPeriodicity(row.periodicity)}</TableCell>;
      },
    },
    {
      name: "Semana",
      accessor: "week",
      customReder: (row: any, column: any) => {
        return <TableCell> {row.week ? `${row.week}` : "-"} </TableCell>;
      },
    },
    {
      name: "Día",
      accessor: "day",
      customReder: (row: any, column: any) => {
        return <TableCell> {row.day ? `${row.day}` : "-"} </TableCell>;
      },
    },
    {
      name: "Días Habilitados",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>
            {row.enabledDays.length > 0 ? row.enabledDays.join(", ") : "-"}
          </TableCell>
        );
      },
    },
    {
      name: "Criterio Feriados",
      accessor: "",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>{formatCriteryFeriados(row.criteryFeriados)}</TableCell>
        );
      },
    },
    {
      name: "Sector",
      accessor: "sector.description",
      customReder: (row: any, column: any) => {
        return (
          <TableCell>{row.sector ? row.sector.description : "-"}</TableCell>
        );
      },
    },
  ];

  useEffect(() => {
    actividadParroquialService
      .getActividadNombres()
      .then((response: any) => {
        const sortedResponse = sortByProperty(response, "name");
        setActividadParroquialNombre(sortedResponse);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (nameId) {
      handleItemChange(nameId, "nameId");
    }
  }, []);

  useEffect(() => {
    if (groupId) {
      handleItemChange(groupId, "groupId");
    }
  }, [activity.activity.nameId]);

  /**
   * Actions for actividad parroquial
   */
  const actividadParroquialActions = [
    {
      icon: (row: any) => <VisibilityIcon />,
      onClick: (row: any) => {
        navigate(
          `/panel/actividad-parroquial/listado-encuentros/${row.id}`,
          {},
        );
      },
    },
  ];

  const fetchActividadesParroquialesPendientes = async (
    page: number,
    pageSize: number,
    filter?: string,
  ) => {
    let response
    //@ts-ignore
    if (encuentro?.parentTurnoId) {
      response = await turnoExpecificationService.getTurnosExpecificationByParentId(
        //@ts-ignore
        encuentro?.parentTurnoId,
      );
    }
    
    const result = response?.data || [];
    const total = response?.total || 0;
    setTotalTurnos(total);

    return result;
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleItemChange = (
    value: any,
    type: "groupId" | "nameId" | "turnoExpecificationId",
    valueName?: any,
  ) => {
    if (type === "groupId") {
      setActivity((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          groupId: value,
        },
      }));

      actividadParroquialService
        .findActividadByActivityNameIdAndGroupId(
          activity.activity.nameId,
          value,
        )
        .then((response: any) => {
          if (response?.id) {
          turnoExpecificationService
            .getEncuentroByActivityId(response.id)
            .then((response: any) => {
              setEncuentro(response[0]);
            })
            .catch((error: any) => {
              console.error("Error al traer turnos", error);
            });
          }
        })
        .catch((error: any) => {
          console.error("Error al buscar actividad parroquial", error);
        });
    } else if (type === "nameId") {
      setActivity((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          nameId: value,
        },
      }));

      grupoParroquialService
        .getGrupoWithOnlyIdAndName(value)
        .then((response: any) => {
          const sortedResponse = sortByProperty(response, "name");
          setGroups(sortedResponse);
        })
        .catch((error: any) => {
          console.error(error);
        });
    } else if (type === "turnoExpecificationId") {
      setActivity((prev) => ({
        ...prev,
        activity: {
          ...prev.activity,
          turnoExpecificationId: value,
        },
      }));
    }
  };

  return (
    <Box sx={stylesListadoActividadParroquial.box1}>
      <LinksAndHeader handleGoBack={handleGoBack} nameId={activity.activity.nameId} groupId={activity.activity.groupId} />
      <Box sx={stylesListadoActividadParroquial.boxPrincipalListado}>
        <div style={stylesListadoActividadParroquial.divListado}></div>
        <Box
          sx={stylesListadoActividadParroquial.gridActividadParroquialInputs}
        >
          <ActividadParroquialInputs
            actividadParroquialNombres={actividadParroquialNombres}
            participante={activity}
            handleItemChange={handleItemChange}
            groups={groups}
            turnosExpecification={encuentro}
            disabledTurno={false}
          />
        </Box>
        <GenericTable<any>
          filtersData={[{ param: "status", value: "pending" }]}
          refresh={forceRefresh}
          columns={actividadParroquialColumns}
          actions={actividadParroquialActions}
          title="turnos"
          fetchData={fetchActividadesParroquialesPendientes}
          totalTurnos={totalTurnos}
          filters={() => {
            return <></>;
          }}
        />
      </Box>
    </Box>
  );
};

export default ListadoDeActividadesParroquialesPendientes;
