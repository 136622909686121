import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { convertHourToUTC } from "../datesUtils/dateUtils";

export function createHorarioData(
  dia: string,
  horarioInicial: string,
  horarioFinal: string,
  id: number,
) {
  return {
    dia,
    horarioInicial,
    horarioFinal,
    id,
  };
}

export const handleEditClick = (
  index: number,
  setEditIndex: Dispatch<SetStateAction<number | null>>,
) => {
  setEditIndex(index);
};

export const handleSaveClick = (
  setEditIndex: Dispatch<SetStateAction<number | null>>,
) => {
  setEditIndex(null);
};

export const handleHorarioChange = (
  index: number,
  field: string,
  value: string,
  setEditedHorarios: Dispatch<SetStateAction<any[]>>,
) => {
  setEditedHorarios((prevState) =>
    prevState.map((horario, i) =>
      i === index ? { ...horario, [field]: value } : horario,
    ),
  );
};

export const handleDescriptionChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setDescription: Dispatch<SetStateAction<string>>,
) => {
  setDescription(event.target.value);
};

export const handleUseChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setUse: Dispatch<SetStateAction<string>>,
) => {
  setUse(event.target.value);
};

export const handleGoBack = (navigate: ReturnType<typeof useNavigate>) => {
  navigate(-1);
};

export const transformHorarios = (horarios: any) => {
  const dias = horarios.map((h: any) => h.dia);
  const diasUnicos = new Set(dias);
  if (diasUnicos.size !== dias.length) {
    throw new Error("No se permiten días duplicados");
  }

  return horarios.map((horario: any) => ({
    dia: horario.dia,
    horarioInicial:
      horario.horarioInicial === "-" || !horario.horarioInicial
        ? null
        : convertHourToUTC(horario.horarioInicial),
    horarioFinal:
      horario.horarioFinal === "-" || !horario.horarioFinal
        ? null
        : convertHourToUTC(horario.horarioFinal),
    id: horario.id || null,
  }));
};
