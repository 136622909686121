import { Grid, Typography } from "@mui/material";
import { CSSProperties } from "react";

export const PeopleActividadParroquialStyles = {
  box1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    height: "100%",
    gap: "2em",
  },
  box2: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "4.5%",
    paddingLeft: "5em",
  },
  box3: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  h2: {
    color: "black",
    textAlign: "center",
  },
  divForm: {
    backgroundColor: "white",
    padding: "4% 4% 4% 3%",
    width: "60%",
    height: "100%",
  },
  texfields: {
    backgroundColor: "white",
  },
  typography: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#264B99",
    marginTop: "0.5em",
    fontSize: "1.1em",
  },
  typographyTipo: {
    color: "#264B99",
    marginBottom: "1em",
    fontSize: "1.1em",
  },
  typographyGroup: {
    color: "#264B99",
    marginTop: "1em",
    fontSize: "1.1em",
    marginBottom: "1em",
  },
  gridFormControl: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingTop: "1em",
  },
  formControlRadios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    paddingBottom: "1em",
  },
  staticDatePicker: {
    backgroundColor: "white",
    borderRadius: "20px",
    margin: "1em",
  },
  formRadioGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: "10em",
    flexWrap: "nowrap",
    width: "100%",
    marginLeft: "12em",
  },
  formRadioGroupItem: {
    flex: 1,
  },
  gridButton: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginTop: "4%",
  },
  buttonSave: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  gridDates: {
    display: "flex",
    flexDirection: "row",
    gap: "2em",
  },
  stack: {
    paddingTop: "1em",
    paddingBottom: "1em",
  },
  typographyStack: {
    color: "#66666B",
  },
  formControlSector: {
    width: "30%",
  },
  formControlSacerdote: {
    width: "30%",
  },
  gridCupos: {
    width: "30%",
  },
  smallText: {
    fontSize: "0.75rem",
  },
  boxRadios: {
    display: "flex",
    flexDirection: "column",
  },
  selectTipo: {
    borderRadius: "5px",
  },
  semanaTipo: {
    borderRadius: "5px",
    width: "10%",
  },
  fechaTipo: {
    borderRadius: "5px",
    width: "10%",
  },
  sectorTipo: {
    maxHeight: "2.5em",
  },
  sacerdoteTipo: {
    maxHeight: "2.5em",
  },
  containerLinks: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 0",
    marginTop: "16px",
  },
  title: {
    borderBottom: "1px solid black",
    marginBottom: "20px",
  },
  link: {
    display: "block",
    marginTop: "8px",
    textDecoration: "none",
    color: "black",
  },
  italicLink: {
    display: "block",
    marginTop: "8px",
    textDecoration: "none",
    color: "grey",
    fontStyle: "italic",
    paddingBottom: "8px",
  },
  activeLink: {
    color: "#264B99",
  },
  addingNewCategory: {
    marginTop: "1em",
    display: "flex",
    gap: "1em",
  },
  addingNewGroup: {
    marginTop: "1em",
    display: "flex",
    gap: "1em",
  },
  GridAddNewCategory: {
    marginRight: "-10px",
  },
  gridAddNewGroup: {
    marginRight: "-10px",
  },
  buttonAddNewCategory: {
    margin: "0",
    minWidth: "80px",
  },
  buttonAddNewGroup: {
    margin: "0",
    minWidth: "80px",
  },
  gridCoordinator: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonAgendarTurnos: {
    marginLeft: "1em",
    position: "relative",
  },
  grid1: {
    "& > .MuiGrid-item": { paddingTop: 0 },
  },
  typography2: {
    textAlign: "center",
    fontSize: "0.8em",
    color: "DodgerBlue",
    opacity: "0.6",
  },
  typography3: {
    textAlign: "center",
    fontSize: "0.8em",
  },
  typographyFecha: {
    color: "#264B99",
    fontSize: "1.1em",
    marginBottom: "0.5em",
    marginTop: "1.5em",
  },
  typographyCarga: {
    color: "#264B99",
    fontSize: "1.1em",
    marginBottom: "0.5em",
    marginTop: "2em",
  },
  gridFormControlChecked: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    paddingTop: "1em",
    gap: "10em",
  },
  typographyuserEnabled: {
    color: "#264B99",
    fontSize: "1.5em",
    marginBottom: "0.5em",
    marginTop: "1.5em",
  },
  typographyNinio: {
    color: "#264B99",
    fontSize: "1.1em",
    marginBottom: "0.5em",
    marginTop: "1.5em",
  },
  gridUbicacionIglesia: {
    marginLeft: "1px",
  },
  typography7: {
    fontWeight: 500,
    color: "#31876C",
    marginTop: "15px",
  },
  typographySchool: {
    color: "gray",
    marginLeft: "0.2em",
    marginTop: "0.2em",
    marginBottom: "0.2em",
  },
  typographySchool1: {
    color: "gray",
    marginLeft: "0.2em",
    marginTop: "0.5em",
    marginBottom: "0.2em",
  },
  gridTipo: {
    paddingBottom: "1em",
    paddingTop: "1em",
  },
  divFormEnabledUser: {
    backgroundColor: "white",
    width: "50%",
    height: "100%",
  },
  gridContainerParticipanteForm: {
    paddingLeft: "16px",
    paddingTop: "16px",
  },
};

export const inputLabelStyles: CSSProperties = {
  backgroundColor: "white",
};
